import React from "react";
import { Card, CardActionArea, CardContent, Grid, Typography } from "@material-ui/core";
import { Ein_1, ein_2, ein_3, ein_4, ein_5, einO_1, einO_2, einO_3, einO_4, einO_5, einO_6, einU_1, einU_2, einU_3, einU_4, einU_5, einU_6 } from "./OpeningTypes/eintilig";
import {Zwei, ZweiL, ZweiR, ZweiLKR, ZweiLR, ZweiLRK
        , ZweiO, ZweiOK, ZweiOKL, ZweiOKLKR,ZweiOKLR,ZweiOKLRK,ZweiOKR,ZweiOL,ZweiOLKR,ZweiOLR,ZweiOLRK,ZweiOR,ZweiOT,ZweiOTK,ZweiOTKL,ZweiOTKLR,ZweiOTKR,ZweiOTL,ZweiOTLR,ZweiOTR,ZweiU,ZweiUK,ZweiUKL,ZweiUKLKR,ZweiUKLR,ZweiUKLRK
      ,ZweiUKR,ZweiUL,ZweiULKR,ZweiULR,ZweiULRK,ZweiUR,ZweiUT,ZweiUTK,ZweiUTKL,ZweiUTKLR,ZweiUTKR,ZweiUTL,ZweiUTLR,ZweiUTR} from './OpeningTypes/zweitlig';
import {Drei, DreiL, DreiLMKL, DreiLMKLR, DreiLMKRR, DreiLML, DreiLMLR, DreiLMRR, DreiLR, DreiMKRR, DreiML, DreiMR, DreiMRR, DreiR, DreiO, DreiOK, DreiOKL, DreiOKLML, DreiOKLMLR, DreiOKLMRR, DreiOKLR, DreiOKML, DreiOKMR, DreiOKMRR, DreiOKR, DreiOL, DreiOLML, DreiOLMLR, DreiOLMRR, DreiOLR, DreiOML, DreiOMR, DreiOMRR, DreiOR, DreiOT, DreiOTK, DreiOTKL, DreiOTKLML, DreiOTKLMLR, DreiOTKLMRR, DreiOTKLR, DreiOTKML, DreiOTKMR, DreiOTKMRR, DreiOTKR, DreiOTL, DreiOTLML, DreiOTLMLR, DreiOTLMRR, DreiOTLR, DreiOTML, DreiOTMR, DreiOTMRR, DreiOTR, DreiU, DreiUMR
, DreiUL , DreiULML, DreiULMLR, DreiULMRR, DreiULR, DreiUML, DreiUMRR, DreiUR, DreiUT , DreiUTK, DreiUTKL, DreiUTKLML, DreiUTKLMLR, DreiUTKLMRR, DreiUTKLR ,DreiUTKML, DreiUTKMR, DreiUTKMRR, DreiUTKR, DreiUTL, DreiUTLML, DreiUTLMLR, DreiUTLMRR, DreiUTLR, DreiUTML, DreiUTMR, DreiUTMRR, DreiUTR} from './OpeningTypes/dreiteilig'
import {rund, rundbogen, rundbogenD, rundbogenDL, rundbogenDLR, rundbogenDO, rundbogenDOK, rundbogenDOKL, rundbogenDOKLKR, rundbogenDOKLR, rundbogenDOKLRK, rundbogenDOKR, rundbogenDOL, rundbogenDOLKR, rundbogenDOLR, rundbogenDOLRK, rundbogenDOR, rundbogenDR, rundbogenL, rundbogenO, rundbogenOL, rundbogenOPR, rundbogenOR, rundbogenOT, rundbogenOTL, rundbogenR, rundobenk, rundschwing, segmentbogen, segmentbogenL, segmentbogenR, segmentdoppel, segmentdoppelL, segmentdoppelLKR, segmentdoppelLR, segmentdoppelLRK, segmentdoppelR, spitzD, spitzDL, spitzDLR, spitzDR, spitzL, spitzLL, spitzR, spitzRR, stumpfD, stumpfDL, stumpfDLR, stumpfDR, stumpfL, stumpfLL, stumpfR, stumpfRR, trapezL, trapezLK, trapezLL, trapezR, trapezRK, trapezRR} from './OpeningTypes/sondertypen';
import styles from "../WinConfigurator.module.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    maxWidth: 345,
    minWidth: 245,
    margin: 10,
  },
  media: {
    maxHeight: 190,
    objectFit: "contain",
  },
});

function OpenType({ setOpenType, upDownLight, winType }) {
  const classes = useStyles();

  let items = [{}];
  if (upDownLight === "ohne" && winType === "Einteilig") {
    items = [
      {
        type: "Festverglast",
        Image: Ein_1,
      },
      {
        type: "Drehkipp links",
        Image: ein_2,
      },
      {
        type: "Drehkipp Rechts",
        Image: ein_3,
      },
      {
        type: "Kipp",
        Image: ein_4,
      },
      {
        type: "Schwingflügel",
        Image: ein_5,
      },
    ];
  } else if (upDownLight === "mit Oberlicht" && winType === "Einteilig") {
    items = [
      {
        type: "Festverglast",
        Image: einO_1,
      },
      {
        type: "Festverglast, Oberlicht kipp",
        Image: einO_2,
      },
      {
        type: "Drehkipp links, Oberlicht fest",
        Image: einO_3,
      },
      {
        type: "Drehkipp rechts, Oberlicht fest",
        Image: einO_4,
      },
      {
        type: "Drehkipp links, Oberlicht kipp",
        Image: einO_5,
      },
      {
        type: "Drehkipp rechts, Oberlicht kipp",
        Image: einO_6,
      },
    ];
  } else if (upDownLight === "mit Unterlicht" && winType === "Einteilig") {
    items = [
      {
        type: "Festverglast",
        Image: einU_1,
      },
      {
        type: "Festverglast, Unterlicht kipp",
        Image: einU_2,
      },
      {
        type: "Drehkipp links, Unterlicht fest",
        Image: einU_3,
      },
      {
        type: "Drehkipp rechts, Unterlicht fest",
        Image: einU_4,
      },
      {
        type: "Drehkipp links, Unterlicht kipp",
        Image: einU_5,
      },
      {
        type: "Drehkipp rechts, Unterlicht kipp",
        Image: einU_6,
      },
    ];
  } else if (upDownLight === "ohne" && winType === "Zweiteilig") {
    items = [
      {
        type: "Festverglast",
        Image: Zwei,
      },
      {
        type: "Links festverglast, rechts drehkipp",
        Image: ZweiR,
      },
      {
        type: "Links drehkipp, rechts Festverglast",
        Image: ZweiL,
      },
      {
        type: "Links kipp, rechts drehkipp",
        Image: ZweiLKR,
      },
      {
        type: "Links drehkipp, rechts kipp",
        Image: ZweiLRK,
      },
      {
        type: "Links drehkipp, rechts drehkipp",
        Image: ZweiLR,
      },
    ];
  } else if (upDownLight === "mit Oberlicht" && winType === "Zweiteilig") {
    items = [
      {
        type: "Festverglast",
        Image: ZweiO,
      },
      {
        type: "Oberlicht festverglast, links festverglast, rechts drehkipp",
        Image: ZweiOR,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, rechts festverglast",
        Image: ZweiOL,
      },
      {
        type: "Oberlicht festverglast, links kipp, rechts drehkipp",
        Image: ZweiOLKR,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, rechts kipp",
        Image: ZweiOLRK,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, rechts drehkipp",
        Image: ZweiOLR,
      },
      {
        type: "Oberlicht kipp, links festverglast, rechts festverglast",
        Image: ZweiOK,
      },
      {
        type: "Oberlicht kipp, links festverglast, rechts drehkipp",
        Image: ZweiOKR,
      },
      {
        type: "Oberlicht kipp, links drehkipp, rechts festverglast",
        Image: ZweiOKL,
      },
      {
        type: "Oberlicht kipp, links kipp, rechts drehkipp",
        Image: ZweiOKLKR,
      },
      {
        type: "Oberlicht kipp, links drehkipp, rechts kipp",
        Image: ZweiOKLRK,
      },
      {
        type: "Oberlicht kipp, links drehkipp, rechts drehkipp",
        Image: ZweiOKLR,
      },
    ];
  } else if (upDownLight === "mit Unterlicht" && winType === "Zweiteilig") {
    items = [
      {
        type: "Festverglast",
        Image: ZweiU,
      },
      {
        type: "Unterlicht festverglast, links festverglast, rechts drehkipp",
        Image: ZweiUR,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, rechts festverglast",
        Image: ZweiUL,
      },
      {
        type: "Unterlicht festverglast, links kipp, rechts drehkipp",
        Image: ZweiULKR,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, rechts kipp",
        Image: ZweiULRK,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, rechts drehkipp",
        Image: ZweiULR,
      },
      {
        type: "Unterlicht kipp, links festverglast, rechts festverglast",
        Image: ZweiUK,
      },
      {
        type: "Unterlicht kipp, links festverglast, rechts drehkipp",
        Image: ZweiUKR,
      },
      {
        type: "Unterlicht kipp, links drehkipp, rechts festverglast",
        Image: ZweiUKL,
      },
      {
        type: "Unterlicht kipp, links kipp, rechts drehkipp",
        Image: ZweiUKLKR,
      },
      {
        type: "Unterlicht kipp, links drehkipp, rechts kipp",
        Image: ZweiUKLRK,
      },
      {
        type: "Unterlicht kipp, links drehkipp, rechts drehkipp",
        Image: ZweiUKLR,
      },
    ];
  } else if (upDownLight === "mit Oberlicht, geteilt" && winType === "Zweiteilig") {
    items = [
      {
        type: "Festverglast",
        Image: ZweiOT,
      },
      {
        type: "Oberlicht festverglast, links festverglast, rechts drehkipp",
        Image: ZweiOTR,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, rechts festverglast",
        Image: ZweiOTL,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, rechts drehkipp",
        Image: ZweiOTLR,
      },
      {
        type: "Oberlicht kipp, links festverglast, rechts festverglast",
        Image: ZweiOTK,
      },
      {
        type: "Oberlicht kipp, links festverglast, rechts drehkipp",
        Image: ZweiOTKR,
      },
      {
        type: "Oberlicht kipp, links drehkipp, rechts festverglast",
        Image: ZweiOTKL,
      },
      {
        type: "Oberlicht kipp, links drehkipp, rechts drehkipp",
        Image: ZweiOTKLR,
      },
    ];
  } else if (upDownLight === "mit Unterlicht, geteilt" && winType === "Zweiteilig") {
    items = [
      {
        type: "Festverglast",
        Image: ZweiUT,
      },
      {
        type: "Unterlicht festverglast, links festverglast, rechts drehkipp",
        Image: ZweiUTR,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, rechts festverglast",
        Image: ZweiUTL,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, rechts drehkipp",
        Image: ZweiUTLR,
      },
      {
        type: "Unterlicht kipp, links festverglast, rechts festverglast",
        Image: ZweiUTK,
      },
      {
        type: "Unterlicht kipp, links festverglast, rechts drehkipp",
        Image: ZweiUTKR,
      },
      {
        type: "Unterlicht kipp, links drehkipp, rechts festverglast",
        Image: ZweiUTKL,
      },
      {
        type: "Unterlicht kipp, links drehkipp, rechts drehkipp",
        Image: ZweiUTKLR,
      },
    ];
  } else if (upDownLight === "ohne" && winType === "Dreiteilig") {
    items = [
      {
        type: "Festverglast",
        Image: Drei,
      },
      {
        type: "Links festverglast, mitte festverglast, rechts drehkipp",
        Image: DreiR,
      },
      {
        type: "Links festverglast, mitte drehkipp rechts, rechts festverglast",
        Image: DreiMR,
      },
      {
        type: "Links drehkipp, mitte festverglast, rechts festverglast",
        Image: DreiL,
      },
      {
        type: "Links festverglast, mitte drehkipp links, rechts festverglast",
        Image: DreiML,
      },
      {
        type: "Links festverglast, mitte kipp links, rechts drehkipp",
        Image: DreiMKRR,
      },
      {
        type: "Links festverglast, mitte drehkipp rechts, rechts drehkipp",
        Image: DreiMRR,
      },
      {
        type: "Links drehkipp, mitte kipp rechts, rechts festverglast",
        Image: DreiLMKL,
      },
      {
        type: "Links drehkipp, mitte drehkipp links, rechts festverglast",
        Image: DreiLML,
      },
      {
        type: "Links drehkipp, mitte festverglast, rechts drehkipp",
        Image: DreiLR,
      },
      {
        type: "Links drehkipp, mitte kipp rechts, rechts drehkipp",
        Image: DreiLMKLR,
      },
      {
        type: "Links drehkipp, mitte kipp links, rechts drehkipp",
        Image: DreiLMKRR,
      },
      {
        type: "Links drehkipp, mitte drehkipp rechts, rechts festverglast",
        Image: DreiLMLR,
      },
      {
        type: "Links drehkipp, mitte drehkipp links, rechts festverglast",
        Image: DreiLMRR,
      },
    ];
  } else if (upDownLight === "mit Oberlicht" && winType === "Dreiteilig") {
    items = [
      {
        type: "Festverglast",
        Image: DreiO,
      },
      {
        type: "Oberlicht festverglast, links festverglast, mitte festverglast, rechts drehkipp",
        Image: DreiOR,
      },
      {
        type: "Oberlicht festverglast, links festverglast, mitte drehkipp rechts, rechts festverglast",
        Image: DreiOMR,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, mitte festverglast, rechts festverglast",
        Image: DreiOL,
      },
      {
        type: "Oberlicht festverglast, links festverglast, mitte drehkipp links, rechts festverglast",
        Image: DreiOML,
      },
      {
        type: "Oberlicht festverglast, links festverglast, mitte drehkipp rechts, rechts drehkipp",
        Image: DreiOMRR,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, mitte kipp rechts, rechts festverglast",
        Image: DreiOLML,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, mitte festverglast, rechts drehkipp",
        Image: DreiOLR,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, mitte drehkipp rechts, rechts drehkipp",
        Image: DreiOLMLR,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, mitte drehkipp links, rechts drehkipp",
        Image: DreiOLMRR,
      },
      {
        type: "Oberlicht kipp, links festverglast, mitte festverglast, rechts festverglast",
        Image: DreiOK,
      },
      {
        type: "Oberlicht kipp, links festverglast, mitte festverglast, rechts drehkipp",
        Image: DreiOKR,
      },
      {
        type: "Oberlicht kipp, links festverglast, mitte drehkipp rechts, rechts festverglast",
        Image: DreiOKMR,
      },
      {
        type: "Oberlicht kipp, links drehkipp, mitte festverglast, rechts festverglast",
        Image: DreiOKL,
      },
      {
        type: "Oberlicht kipp, links festverglast, mitte drehkipp links, rechts festverglast",
        Image: DreiOKML,
      },
      {
        type: "Oberlicht kipp, links festverglast, mitte drehkipp rechts, rechts drehkipp",
        Image: DreiOKMRR,
      },
      {
        type: "Oberlicht kipp, links drehkipp, mitte kipp rechts, rechts festverglast",
        Image: DreiOKLML,
      },
      {
        type: "Oberlicht kipp, links drehkipp, mitte festverglast, rechts drehkipp",
        Image: DreiOKLR,
      },
      {
        type: "Oberlicht kipp, links drehkipp, mitte drehkipp rechts, rechts festverglast",
        Image: DreiOKLMLR,
      },
      {
        type: "Oberlicht kipp, links drehkipp, mitte drehkipp links, rechts festverglast",
        Image: DreiOKLMRR,
      },
    ];
  } else if (upDownLight === "mit Unterlicht" && winType === "Dreiteilig") {
    items = [
      {
        type: "Festverglast",
        Image: DreiU,
      },
      {
        type: "Unterlicht festverglast, links festverglast, mitte festverglast, rechts drehkipp",
        Image: DreiUR,
      },
      {
        type: "Unterlicht festverglast, links festverglast, mitte drehkipp rechts, rechts festverglast",
        Image: DreiUMR,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, mitte festverglast, rechts festverglast",
        Image: DreiUL,
      },
      {
        type: "Unterlicht festverglast, links festverglast, mitte drehkipp links, rechts festverglast",
        Image: DreiUML,
      },
      {
        type: "Unterlicht festverglast, links festverglast, mitte drehkipp rechts, rechts drehkipp",
        Image: DreiUMRR,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, mitte kipp rechts, rechts festverglast",
        Image: DreiULML,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, mitte festverglast, rechts drehkipp",
        Image: DreiULR,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, mitte drehkipp rechts, rechts drehkipp",
        Image: DreiULMLR,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, mitte drehkipp links, rechts drehkipp",
        Image: DreiULMRR,
      },
    ];
  } else if (upDownLight === "mit Oberlicht, geteilt" && winType === "Dreiteilig") {
    items = [
      {
        type: "Festverglast",
        Image: DreiOT,
      },
      {
        type: "Oberlicht festverglast, links festverglast, mitte festverglast, rechts drehkipp",
        Image: DreiOTR,
      },
      {
        type: "Oberlicht festverglast, links festverglast, mitte drehkipp rechts, rechts festverglast",
        Image: DreiOTMR,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, mitte festverglast, rechts festverglast",
        Image: DreiOTL,
      },
      {
        type: "Oberlicht festverglast, links festverglast, mitte drehkipp links, rechts festverglast",
        Image: DreiOTML,
      },
      {
        type: "Oberlicht festverglast, links festverglast, mitte drehkipp rechts, rechts drehkipp",
        Image: DreiOTMRR,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, mitte kipp rechts, rechts festverglast",
        Image: DreiOTLML,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, mitte festverglast, rechts drehkipp",
        Image: DreiOTLR,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, mitte drehkipp rechts, rechts drehkipp",
        Image: DreiOTLMLR,
      },
      {
        type: "Oberlicht festverglast, links drehkipp, mitte drehkipp links, rechts drehkipp",
        Image: DreiOTLMRR,
      },
      {
        type: "Oberlicht kipp, links festverglast, mitte festverglast, rechts festverglast",
        Image: DreiOTK,
      },
      {
        type: "Oberlicht kipp, links festverglast, mitte festverglast, rechts drehkipp",
        Image: DreiOTKR,
      },
      {
        type: "Oberlicht kipp, links festverglast, mitte drehkipp rechts, rechts festverglast",
        Image: DreiOTKMR,
      },
      {
        type: "Oberlicht kipp, links drehkipp, mitte festverglast, rechts festverglast",
        Image: DreiOTKL,
      },
      {
        type: "Oberlicht kipp, links festverglast, mitte drehkipp links, rechts festverglast",
        Image: DreiOTKML,
      },
      {
        type: "Oberlicht kipp, links festverglast, mitte drehkipp rechts, rechts drehkipp",
        Image: DreiOTKMRR,
      },
      {
        type: "Oberlicht kipp, links drehkipp, mitte kipp rechts, rechts festverglast",
        Image: DreiOTKLML,
      },
      {
        type: "Oberlicht kipp, links drehkipp, mitte festverglast, rechts drehkipp",
        Image: DreiOTKLR,
      },
      {
        type: "Oberlicht kipp, links drehkipp, mitte drehkipp rechts, rechts festverglast",
        Image: DreiOTKLMLR,
      },
      {
        type: "Oberlicht kipp, links drehkipp, mitte drehkipp links, rechts festverglast",
        Image: DreiOTKLMRR,
      },
    ];
  } else if (upDownLight === "mit Unterlicht, geteilt" && winType === "Dreiteilig") {
    items = [
      {
        type: "Festverglast",
        Image: DreiUT,
      },
      {
        type: "Unterlicht festverglast, links festverglast, mitte festverglast, rechts drehkipp",
        Image: DreiUTR,
      },
      {
        type: "Unterlicht festverglast, links festverglast, mitte drehkipp rechts, rechts festverglast",
        Image: DreiUTMR,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, mitte festverglast, rechts festverglast",
        Image: DreiUTL,
      },
      {
        type: "Unterlicht festverglast, links festverglast, mitte drehkipp links, rechts festverglast",
        Image: DreiUTML,
      },
      {
        type: "Unterlicht festverglast, links festverglast, mitte drehkipp rechts, rechts drehkipp",
        Image: DreiUTMRR,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, mitte kipp rechts, rechts festverglast",
        Image: DreiUTLML,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, mitte festverglast, rechts drehkipp",
        Image: DreiUTLR,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, mitte drehkipp rechts, rechts drehkipp",
        Image: DreiUTLMLR,
      },
      {
        type: "Unterlicht festverglast, links drehkipp, mitte drehkipp links, rechts drehkipp",
        Image: DreiUTLMRR,
      },
      {
        type: "Unterlicht kipp, links festverglast, mitte festverglast, rechts festverglast",
        Image: DreiUTK,
      },
      {
        type: "Unterlicht kipp, links festverglast, mitte festverglast, rechts drehkipp",
        Image: DreiUTKR,
      },
      {
        type: "Unterlicht kipp, links festverglast, mitte drehkipp rechts, rechts festverglast",
        Image: DreiUTKMR,
      },
      {
        type: "Unterlicht kipp, links drehkipp, mitte festverglast, rechts festverglast",
        Image: DreiUTKL,
      },
      {
        type: "Unterlicht kipp, links festverglast, mitte drehkipp links, rechts festverglast",
        Image: DreiUTKML,
      },
      {
        type: "Unterlicht kipp, links festverglast, mitte drehkipp rechts, rechts drehkipp",
        Image: DreiUTKMRR,
      },
      {
        type: "Unterlicht kipp, links drehkipp, mitte kipp rechts, rechts festverglast",
        Image: DreiUTKLML,
      },
      {
        type: "Unterlicht kipp, links drehkipp, mitte festverglast, rechts drehkipp",
        Image: DreiUTKLR,
      },
      {
        type: "Unterlicht kipp, links drehkipp, mitte drehkipp rechts, rechts festverglast",
        Image: DreiUTKLMLR,
      },
      {
        type: "Unterlicht kipp, links drehkipp, mitte drehkipp links, rechts festverglast",
        Image: DreiUTKLMRR,
      },
    ];
  } else if (upDownLight === "Stumpf 1-Teilig" && winType === "Sondertypen") {
    items = [
      {
        type: "Rechts festverglast",
        Image: stumpfR,
      },
      {
        type: "Links festverglast",
        Image: stumpfL,
      },
      {
        type: "Rechts drehkipp links",
        Image: stumpfRR,
      },
      {
        type: "Links drehkipp rechts",
        Image: stumpfLL,
      },
    ];
  } else if (upDownLight === "Trapez 3-Eckig" && winType === "Sondertypen") {
    items = [
      {
        type: "Rechts festverglast",
        Image: trapezR,
      },
      {
        type: "Rechts drehkipp",
        Image: trapezRR,
      },
      {
        type: "Rechts kipp",
        Image: trapezRK,
      },
      {
        type: "Links festverglast",
        Image: trapezL,
      },
      {
        type: "Links drehkipp",
        Image: trapezLL,
      },
      {
        type: "Links kipp",
        Image: trapezLK,
      },
    ];
  } else if (upDownLight === "Rund" && winType === "Sondertypen") {
    items = [
      {
        type: "Festverglast",
        Image: rund,
      },
      {
        type: "Oben kipp",
        Image: rundobenk,
      },
      {
        type: "Schwingflügel",
        Image: rundschwing,
      },
    ];
  } else if (upDownLight === "Spitz 1-Teilig" && winType === "Sondertypen") {
    items = [
      {
        type: "Rechts festverglast",
        Image: spitzR,
      },
      {
        type: "Links festverglast",
        Image: spitzL,
      },
      {
        type: "Rechts drehkipp links",
        Image: spitzRR,
      },
      {
        type: "Links drehkipp rechts",
        Image: spitzLL,
      },
    ];
  } else if (upDownLight === "Spitz 2-Teilig" && winType === "Sondertypen") {
    items = [
      {
        type: "Festverglast",
        Image: spitzD,
      },
      {
        type: "Links festverglast, rechts drehkipp links",
        Image: spitzDL,
      },
      {
        type: "Links drehkipp rechts, rechts drehkipp links",
        Image: spitzDR,
      },
      {
        type: "Links drehkipp rechts, rechts drehkipp links",
        Image: spitzDLR,
      },
    ];
  } else if (upDownLight === "Stumpf 2-Teilig" && winType === "Sondertypen") {
    items = [
      {
        type: "Festverglast",
        Image: stumpfD,
      },
      {
        type: "Links festverglast, rechts drehkipp links",
        Image: stumpfDL,
      },
      {
        type: "Links drehkipp rechts, rechts drehkipp links",
        Image: stumpfDR,
      },
      {
        type: "Links drehkipp rechts, rechts drehkipp links",
        Image: stumpfDLR,
      },
    ];
  } else if (upDownLight === "Rundbogen" && winType === "Sondertypen") {
    items = [
      {
        type: "Festverglast",
        Image: rundbogen,
      },
      {
        type: "Drehkipp rechts",
        Image: rundbogenL,
      },
      {
        type: "Drehkipp links",
        Image: rundbogenR,
      },
    ];
  } else if (upDownLight === "Rundbogen, mit Obenlicht" && winType === "Sondertypen") {
    items = [
      {
        type: "Festverglast",
        Image: rundbogenO,
      },
      {
        type: "Drehkipp rechts, oben Festverglast",
        Image: rundbogenOL,
      },
      {
        type: "Drehkipp links, oben Festverglast",
        Image: rundbogenOR,
      },
      {
        type: "Festverglast, oben kipp",
        Image: rundbogenOT,
      },
      {
        type: "Drehkipp rechts, oben kipp",
        Image: rundbogenOTL,
      },
      {
        type: "Drehkipp links, oben kipp",
        Image: rundbogenOPR,
      },
    ];
  } else if (upDownLight === "Segementbogen" && winType === "Sondertypen") {
    items = [
      {
        type: "Festverglast",
        Image: segmentbogen,
      },
      {
        type: "Drehkipp rechts",
        Image: segmentbogenL,
      },
      {
        type: "Drehkipp links",
        Image: segmentbogenR,
      },
    ];
  } else if (upDownLight === "Rundbogen 2-Teilig" && winType === "Sondertypen") {
    items = [
      {
        type: "Festverglast",
        Image: rundbogenD,
      },
      {
        type: "Links kipp, rechts drehkipp rechts",
        Image: rundbogenDR,
      },
      {
        type: "Links drehkipp links, rechts kipp",
        Image: rundbogenDL,
      },
      {
        type: "Links drehkipp links, rechts drehkipp rechts",
        Image: rundbogenDLR,
      },
    ];
  } else if (upDownLight === "Rundbogen 2-Teilig, mit Obenlicht" && winType === "Sondertypen") {
    items = [
      {
        type: "Festverglast",
        Image: rundbogenDO,
      },
      {
        type: "Links festverglast, rechts drehkipp rechts, oben festverglast",
        Image: rundbogenDOR,
      },
      {
        type: "Links drehkipp links, rechts festverglast, oben festverglast",
        Image: rundbogenDOL,
      },
      {
        type: "Links kipp, rechts drehkipp rechts, oben festverglast",
        Image: rundbogenDOLKR,
      },
      {
        type: "Links drehkipp links, rechts kipp, oben festverglast",
        Image: rundbogenDOLRK,
      },
      {
        type: "Links drehkipp links, rechts drehkipp rechts, oben festverglast",
        Image: rundbogenDOLR,
      },
      {
        type: "Links festverglast, rechts festverglast, oben kipp",
        Image: rundbogenDOK,
      },
      {
        type: "Links festverglast, rechts drehkipp rechts, oben kipp",
        Image: rundbogenDOKR,
      },
      {
        type: "Links drehkipp links, rechts festverglast, oben kipp",
        Image: rundbogenDOKL,
      },
      {
        type: "Links kipp, rechts drehkipp rechts, oben kipp",
        Image: rundbogenDOKLKR,
      },
      {
        type: "Links drehkipp links, rechts kipp, oben kipp",
        Image: rundbogenDOKLRK,
      },
      {
        type: "Links drehkipp links, rechts drehkipp rechts, oben kipp",
        Image: rundbogenDOKLR,
      },
    ];
  } else if (upDownLight === "Segementbogen 2-Teilig" && winType === "Sondertypen") {
    items = [
      {
        type: "Festverglast",
        Image: segmentdoppel,
      },
      {
        type: "Links festverglast, rechts drehkipp rechts",
        Image: segmentdoppelR,
      },
      {
        type: "Links drehkipp links, rechts festverglast",
        Image: segmentdoppelL,
      },
      {
        type: "Links kipp, rechts drehkipp rechts",
        Image: segmentdoppelLKR,
      },
      {
        type: "Links drehkipp links, rechts kipp",
        Image: segmentdoppelLRK,
      },
      {
        type: "Links drehkipp links, rechts drehkipp rechts",
        Image: segmentdoppelLR,
      },
    ];
  }

  return (
    <Grid className={styles.items}>
      <Grid className={styles.containerItems}>
        <Grid container spacing={3} justify='center'>
          {items.map((item) => (
            <Card className={classes.root} key={item.type}>
              <CardActionArea onClick={() => setOpenType(item.type)}>
                <CardContent>
                  <img className={classes.media} src={item.Image} alt={item.type} />
                  <Typography gutterBottom variant='subtitle1' component='h2'>
                    {item.type}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OpenType;
