import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Nav.module.css';
import cx from 'classnames';

class Nav extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toggle: false,
			urlPath: window.location.pathname.split('/').filter(Boolean)[0] || '/'
		};
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		this.setState({
			collapse: !this.state.collapse
		});
	}

	updateUrlPathState = (customPath) => {
		const path =
			typeof customPath === 'string' ? customPath : window.location.pathname.split('/').filter(Boolean)[0];
		this.setState({
			urlPath: path || '/'
		});
		this.setState({ toggle: false });
	};

	Toggle = () => {
		this.setState({ toggle: !this.state.toggle });
	};

	render() {
		const { toggle, urlPath } = this.state;

		return (
			<div>
				<nav>
					<div className={styles.leftnav}>
						<div>
							<h4>
								<strong>FENSTER UND CO</strong>
							</h4>
						</div>
						<div
							className={cx(styles.burger, styles.burgerslip, toggle ? styles.open : 0)}
							onClick={this.Toggle}
						>
							<div className={styles.burgerlines} />
						</div>
					</div>
					<ul className={toggle ? styles.linksburger : styles.links}>
						<li
							className={cx(styles.link, urlPath === '/' ? styles.active : 0)}
							onClick={this.updateUrlPathState}
						>
							<Link to="/">Home</Link>
						</li>
						<li
							className={cx(styles.link, urlPath === 'garage' ? styles.active : 0)}
							onClick={this.updateUrlPathState}
						>
							<Link to="garage">Garage</Link>
						</li>
						<li
							className={cx(styles.link, urlPath === 'door' ? styles.active : 0)}
							onClick={this.updateUrlPathState}
						>
							<Link to="door">Tür</Link>
						</li>
						<li
							className={cx(styles.link, urlPath === 'config' ? styles.active : 0)}
							onClick={this.updateUrlPathState}
						>
							<Link to="config">Konfigurator</Link>
						</li>
						<li
							className={cx(styles.link, urlPath === 'contact' ? styles.active : 0)}
							onClick={this.updateUrlPathState}
						>
							<Link to="contact">Kontakt</Link>
						</li>
					</ul>
				</nav>
			</div>
		);
	}
}

export default Nav;
