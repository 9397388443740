import React from "react";
import { Card, CardActionArea, CardContent, Grid, Typography } from "@material-ui/core";
import styles from "../WinConfigurator.module.css";
import { makeStyles } from "@material-ui/core/styles";
import { Weiss, Nussbaum, Mooreiche, Mahagoni, Grau, GoldenOak, Dunkelgruen, db703, BraunDekor, Basaltgrau, Anthrazitgrau, Douglasie } from "./Colors/Inside";

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    maxWidth: 345,
    minWidth: 245,
    margin: 10,
  },
  media: {
    maxHeight: 190,
    objectFit: "contain",
  },
});

function ColorsInside({ setColor, material }) {
  const classes = useStyles();

  let items = [{}];
  if (material === "Kunstoff") {
    items = [
      {
        color: "Weiß",
        Image: Weiss,
      },
      {
        color: "Mahagoni",
        Image: Mahagoni,
      },
      {
        color: "Golden Oak",
        Image: GoldenOak,
      },
      {
        color: "Nussbaum",
        Image: Nussbaum,
      },
      {
        color: "Anthrazit (RAL 7016)",
        Image: Anthrazitgrau,
      },
      {
        color: "Grau (RAL 7001)",
        Image: Grau,
      },
      {
        color: "Basaltgrau (RAL 7012)",
        Image: Basaltgrau,
      },
      {
        color: "DB 703",
        Image: db703,
      },
      {
        color: "Mooreiche",
        Image: Mooreiche,
      },
      {
        color: "Braun dekor",
        Image: BraunDekor,
      },
      {
        color: "Dunkelgrün",
        Image: Dunkelgruen,
      },
    ];
  } else if (material === "Kunstoff-Aluminium") {
    items = [
      {
        color: "Weiß",
        Image: Weiss,
      },
      {
        color: "Douglasie",
        Image: Douglasie,
      },
      {
        color: "Golden Oak",
        Image: GoldenOak,
      },
      {
        color: "Nussbaum",
        Image: Nussbaum,
      },
      {
        color: "Anthrazit (RAL 7016)",
        Image: Anthrazitgrau,
      },
      {
        color: "Basaltgrau (RAL 7012)",
        Image: Basaltgrau,
      },
      {
        color: "Grau (RAL 7001)",
        Image: Grau,
      },
      {
        color: "DB 703",
        Image: db703,
      },
    ];
  }
  return (
    <Grid className={styles.items}>
      <Grid className={styles.containerItems}>
        <Grid container spacing={3} justify='center'>
          {items.map((item) => (
            <Card className={classes.root} key={item.color}>
              <CardActionArea onClick={() => setColor(item.color)}>
                <CardContent>
                  <img className={classes.media} src={item.Image} alt={item.color} />
                  <Typography gutterBottom variant='subtitle1' component='h2'>
                    {item.color}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ColorsInside;
