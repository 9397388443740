import React from "react";
import { Card, CardActionArea, CardContent, Grid, Typography } from "@material-ui/core";
import {
  EinTOhne,
  EinTOber,
  EinTUnter,
  ZweiTOhne,
  ZweiTOber,
  ZweiTOberGT,
  ZweiTUnter,
  ZweiTUnterGT,
  DreiTOhne,
  DreiTOber,
  DreiTOberGT,
  DreiTUnter,
  DreiTUnterGT,
  Rundbogen,
  RundbogendoppelOben,
  RundbogenDoppel,
  rund,
  segementbogendoppel,
  Segementbogen,
  spitz,
  Stumpf,
  StumpfDoppel,
  RundbogenOben,
  trapez,
  SpitzDoppel,
} from "./UpDownLight/updownlight";
import styles from "../WinConfigurator.module.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    maxWidth: 345,
    minWidth: 245,
    margin: 10,
  },
  media: {
    maxHeight: 190,
    objectFit: "contain",
  },
});

function UpDownLight({ setUpDownLigt, material }) {
  const classes = useStyles();

  let items = [{}];
  if (material === "Einteilig") {
    items = [
      {
        type: "ohne",
        Image: EinTOhne,
      },
      {
        type: "mit Oberlicht",
        Image: EinTOber,
      },
      {
        type: "mit Unterlicht",
        Image: EinTUnter,
      },
    ];
  } else if (material === "Zweiteilig") {
    items = [
      {
        type: "ohne",
        Image: ZweiTOhne,
      },
      {
        type: "mit Oberlicht",
        Image: ZweiTOber,
      },
      {
        type: "mit Oberlicht, geteilt",
        Image: ZweiTOberGT,
      },
      {
        type: "mit Unterlicht",
        Image: ZweiTUnter,
      },
      {
        type: "mit Unterlicht, geteilt",
        Image: ZweiTUnterGT,
      },
    ];
  } else if (material === "Dreiteilig") {
    items = [
      {
        type: "ohne",
        Image: DreiTOhne,
      },
      {
        type: "mit Oberlicht",
        Image: DreiTOber,
      },
      {
        type: "mit Oberlicht, geteilt",
        Image: DreiTOberGT,
      },
      {
        type: "mit Unterlicht",
        Image: DreiTUnter,
      },
      {
        type: "mit Unterlicht, geteilt",
        Image: DreiTUnterGT,
      },
    ];
  } else if (material === "Sondertypen") {
    items = [
      {
        type: "Stumpf 1-Teilig",
        Image: Stumpf,
      },
      {
        type: "Trapez 3-Eckig",
        Image: trapez,
      },
      {
        type: "Rund",
        Image: rund,
      },
      {
        type: "Spitz 1-Teilig",
        Image: spitz,
      },
      {
        type: "Spitz 2-Teilig",
        Image: SpitzDoppel,
      },
      {
        type: "Stumpf 2-Teilig",
        Image: StumpfDoppel,
      },
      {
        type: "Rundbogen",
        Image: Rundbogen,
      },
      {
        type: "Rundbogen, mit Obenlicht",
        Image: RundbogenOben,
      },
      {
        type: "Segementbogen",
        Image: Segementbogen,
      },
      {
        type: "Rundbogen 2-Teilig",
        Image: RundbogenDoppel,
      },
      {
        type: "Rundbogen 2-Teilig, mit Obenlicht",
        Image: RundbogendoppelOben,
      },
      {
        type: "Segementbogen 2-Teilig",
        Image: segementbogendoppel,
      },
    ];
  }
  return (
    <Grid className={styles.items}>
      <Grid className={styles.containerItems}>
        <Grid container spacing={3} justify='center'>
          {items.map((item) => (
            <Card className={classes.root} key={item.type}>
              <CardActionArea onClick={() => setUpDownLigt(item.type)}>
                <CardContent>
                  <img className={classes.media} src={item.Image} alt={item.type} />
                  <Typography gutterBottom variant='subtitle1' component='h2'>
                    {item.type}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UpDownLight;
