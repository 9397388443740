import React from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import styles from "./ItemRating.module.css";

const ItemRating = (props) => {
  return (
    <Paper className={styles.paper}>
      <Grid
        className={styles.container}
        container
        direction="column"
        justify="space-evenly"
        alignItems="center"
        item
        xs={12}
      >
        <img className={styles.itemimg} src={props.item.Image} alt="family" />
        <Typography variant="h4">{props.item.Name}</Typography>
        <Typography variant="subtitle2">{props.item.Description}</Typography>
        <Rating name="read-only" value={5} readOnly />
      </Grid>
    </Paper>
  );
};

export default ItemRating;
