import React from "react";
import { Section, Ratings } from "../components";
import doorImg from "../images/doorMain.png";

class Door extends React.Component {
  render() {
    return (
      <div style={{ width: "100%" }}>
        <Section
          Heading={"Tür"}
          Text={
            "Zusätzlich zu Ihren Fenstern, können Sie bei uns auch die passenden Türen bestellen. Wenn Sie auf der Suche nach hochwertigen Haustüren sind, schreiben Sie uns einfach an und wir finden die passende Tür, die ganz Ihren Wünschen und Vorstellungen entspricht. Diese können außerdem von unserem ausgebildeten Fachpersonal vor Ort eingebaut werden. Bei uns profitieren Sie von langjähriger Erfahrung, umfangreichem Fachwissen und flexiblem Service."
          }
          imgage={doorImg}
          imagealt={"Über uns"}
        />
        <Ratings />
      </div>
    );
  }
}
export default Door;
