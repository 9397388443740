import React, { useState } from "react";
import { TextField, Button, styled, Snackbar } from "@material-ui/core";
import styles from "../WinConfigurator.module.css";
import MuiAlert from "@material-ui/lab/Alert";

const MyButton = styled(Button)({
  background: "linear-gradient(to right, #4facfe 0%, #00f2fe 100%)",
  border: 0,
  borderRadius: 3,
  boxShadow: "0 3px 5px 2px rgba(0, 190, 255, .3)",
  color: "white",
  height: 48,
  padding: "0 30px",
  margin: "30px 0",
});

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function WinSize({ setSizes }) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [open, setOpen] = useState(false);

  const sendData = () => {
    if (width > 300 && height > 300) {
      setSizes(width, height);
    } else {
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={styles.sizes}>
      <TextField
        id='outlined-number'
        label='Breite in mm'
        variant='outlined'
        type='number'
        InputLabelProps={{
          shrink: true,
        }}
        value={width}
        onChange={(e) => {
          setWidth(e.target.value);
        }}
      />
      <TextField
        id='outlined-number'
        label='Höhe in mm'
        variant='outlined'
        type='number'
        InputLabelProps={{
          shrink: true,
        }}
        value={height}
        onChange={(e) => {
          setHeight(e.target.value);
        }}
      />
      <MyButton onClick={sendData}>Auswählen</MyButton>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='error'>
          Breite und Höhe müssen größer als 300mm sein.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default WinSize;
