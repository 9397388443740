import React from "react";
import { UG063, UG073, UG102, UG112 } from "./Glasses/glasses";
import { Card, CardActionArea, CardContent, Grid, Typography } from "@material-ui/core";
import styles from "../WinConfigurator.module.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    maxWidth: 345,
    minWidth: 245,
    margin: 10,
  },
  media: {
    maxHeight: 190,
    objectFit: "contain",
  },
});

function Glasses({ setGlasses }) {
  const classes = useStyles();

  const items = [
    {
      type: "Ug 1,1 2-fach Verglasung",
      Image: UG112,
    },
    {
      type: "Ug 1,0 2-fach Verglasung",
      Image: UG102,
    },
    {
      type: "Ug 0,7 3-fach Verglasung",
      Image: UG073,
    },
    {
      type: "Ug 0,6 3-fach Verglasung",
      Image: UG063,
    },
  ];
  return (
    <Grid className={styles.items}>
      <Grid className={styles.containerItems}>
        <Grid container spacing={3} justify='center'>
          {items.map((item) => (
            <Card className={classes.root} key={item.type}>
              <CardActionArea onClick={() => setGlasses(item.type)}>
                <CardContent>
                  <img className={classes.media} src={item.Image} alt={item.type} />
                  <Typography gutterBottom variant='subtitle1' component='h2'>
                    {item.type}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Glasses;
