import React from "react";
import { Section, Ratings } from "../components";
import GarageImg from "../images/garageMain.jpg";

class Garage extends React.Component {
  render() {
    return (
      <div style={{ width: "100%" }}>
        <Section
          Heading={"Garage"}
          Text={
            "Jedes Haus ist individuell, genau wie eine dazugehörige Garage. Wir finden für Sie die optimale Lösung für jedes Platzverhältnis. Informieren Sie sich bei uns über die weitreichenden Möglichkeiten und lassen Sie sich Ihre Garage direkt vor Ort von unserem Fachpersonal einbauen.\n\nWir beraten Sie dazu gerne!"
          }
          imgage={GarageImg}
          imagealt={"Über uns"}
        />
        <Ratings />
      </div>
    );
  }
}
export default Garage;
