import React from "react";
import {
  Grid,
  Paper,
  Typography,
  styled,
  Button,
  TextField,
} from "@material-ui/core";
import styles from "./ContactForm.module.css";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { DropzoneArea } from "material-ui-dropzone";
import { sendMail } from '../../api';
import Swal from 'sweetalert2'

const MyButton = styled(Button)({
  background: "linear-gradient(to right, #4facfe 0%, #00f2fe 100%)",
  border: 0,
  borderRadius: 3,
  boxShadow: "0 3px 5px 2px rgba(0, 190, 255, .3)",
  color: "white",
  height: 48,
  padding: "0 30px",
  margin: "30px 0",
});

class ContactForm extends React.Component {
  state = {
    lastName: "",
    firstName: "",
    mail: "",
    phone: "",
    text: "",
    files: [],
  };

  handleChange = (event) => {
    const mail = event.target.value;
    this.setState({ mail });
  };

  handleChangeimg(files) {
    this.setState({
      files: files,
    });
  }
  handleSubmit = () => {
    sendMail(this.state);
    Swal.fire({
      title: 'Erfolgreich',
      text: 'Ihre Nachricht wurde erfolgreich versendet. Wir melden uns schnellstmöglichs bei Ihnen!',
      icon: 'success',
      confirmButtonText: 'Danke'
    })
  };

  render() {
    return (
      <div className={styles.items}>
        <div className={styles.container}>
          <ValidatorForm
            ref="form"
            onSubmit={this.handleSubmit}
            onError={(errors) => console.log(errors)}
          >
            <Grid container spacing={3} justify="center">
              <Grid item xs={12}>
                <Paper className={styles.paper}>
                  <Typography variant="h2">Kontaktform</Typography>
                  <Typography varian="body1" paragraph={true}>
                    Bei Fragen können Sie sich gerne über unser Kontaktformular
                    an uns wenden. Wir erstellen Ihnen außerdem innerhalb von 7
                    Tagen Ihr individuelles Angebot.
                  </Typography>

                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item spacing={3} xs={12} md={4}>
                      <TextField
                        fullWidth={true}
                        required
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        type="text"
                        value={this.state.lastName}
                        onChange={(e) => {
                          this.setState({ lastName: e.target.value });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth={true}
                        required
                        id="outlined-basic"
                        label="Vorname"
                        variant="outlined"
                        type="text"
                        value={this.state.firstName}
                        onChange={(e) => {
                          this.setState({ firstName: e.target.value });
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth={true}
                        id="outlined-basic"
                        label="Telefonnummer"
                        variant="outlined"
                        type="tel"
                        validators={[
                          'matchRegexp:^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$',
                        ]}
                        value={this.state.phone}
                        onChange={(e) => {
                          this.setState({ phone: e.target.value });
                        }}
                      />{" "}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextValidator
                        label="E-Mail"
                        required
                        fullWidth={true}
                        onChange={(e) => {
                          this.setState({ mail: e.target.value });
                        }}
                        name="mail"
                        value={this.state.mail}
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "Bitte füllen Sie dieses Feld aus.",
                          "E-Mail ist nicht korrekt.",
                        ]}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={8}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Ihre Nachricht"
                        required
                        fullWidth={true}
                        multiline
                        rows={4}
                        variant="outlined"
                        value={this.state.text}
                        onChange={(e) => {
                          this.setState({ text: e.target.value });
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={8}>
                      <DropzoneArea
                        onChange={this.handleChangeimg.bind(this)}
                        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                        maxFileSize={5000000}
                        filesLimit={5}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    direction="row-reverse"
                    justify="center"
                    alignItems="flex-end"
                  >
                    <Grid item xs={12} md={8}>
                      <MyButton type="submit">Senden</MyButton>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>{" "}
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

export default ContactForm;
