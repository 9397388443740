import React from "react";
import { Hero, Cards, Section, Ratings } from "../components";
import Aboutus from "../images/aboutus.jpg";

class Home extends React.Component {
  render() {
    return (
      <div style={{ width: "100%" }}>
        <Hero
          Text={
            "Ob Standard oder Sonderanfertigung, bei uns bekommen Sie die beste Qualität zum fairen Preis"
          }
        />
        <Cards />
        <Section
          Heading={"Über uns"}
          Text={
            "Sie sind auf der Suche nach einer Möglichkeit, Ihre Fenster ganz leicht und selbst konfigurieren zu können? Dann sind Sie bei uns richtig. Denn bei uns können Sie Ihr Wunschfenster selbst konfigurieren und wir kommen zum Einbau direkt zu Ihnen nach Hause.\n\nSeit bereits über 10 Jahren sind wir für Sie in Kassel im Bereich Haus und Garten tätig und speziell auf alle anfallenden Arbeiten am Gebäude spezialisiert. Sie profitieren von fachlichem Know-how und einer zuverlässigen Arbeitsweise.\n\nBei uns bekommen Sie beste Qualität zu einem fairen Preis. Die Lieferung ist schnell und der Einbau erfolgt unkompliziert und professionell.Unser Team arbeitet immer Hand in Hand und bietet einen umfangreichen Service rund um Ihr Haus."
          }
          imgage={Aboutus}
          imagealt={"Über uns"}
        />
        <Ratings />
      </div>
    );
  }
}
export default Home;
