import React from "react";
import styles from "./Hero.module.css";
import HeroImg from "./HeroImg";

const Hero = ({ Text }) => {
  return (
    <div className={styles.hero}>
      <div className={styles.herotext}>{Text}</div>
      <div className={styles.products}>
        <HeroImg />
      </div>
    </div>
  );
};

export default Hero;
