import React from 'react';
import styles from './Footer.module.css';
import Button from '@material-ui/core/Button';

const Footer = ({ Text }) => {
	return (
		<footer className={styles.container}>
			Designed and Developed by{' '}
			<Button href="http://marcel-schmager.com/" target="noopener" className={styles.btntext}>
				Marcel Schmager
			</Button>
			|{' '}
			<Button href="impressum" target="noopener" className={styles.btntext}>
				Impressum
			</Button>
		</footer>
	);
};

export default Footer;
