import React from 'react';
import { Paper, Grid, Typography, Button, styled } from '@material-ui/core';
import styles from './Section.module.css';

const MyButton = styled(Button)({
	background: 'linear-gradient(to right, #4facfe 0%, #00f2fe 100%)',
	border: 0,
	borderRadius: 3,
	boxShadow: '0 3px 5px 2px rgba(0, 190, 255, .3)',
	color: 'white',
	height: 48,
	padding: '0 30px',
	margin: '30px 0'
});

const Section = ({ Heading, Text, imgage, imagealt }) => {
	return (
		<div className={styles.items}>
			<div className={styles.container}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper className={styles.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={8}>
									<Typography variant="h3" gutterBottom>
										{Heading}
									</Typography>
									<Typography variant="body1" gutterBottom>
										{Text}
									</Typography>
								</Grid>
								<Grid container justify="center" item xs={12} md={4}>
									<Grid item xs={12}>
										<img className={styles.sectionimg} src={imgage} alt={imagealt} />
										<MyButton href="contact">Kontakt</MyButton>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default Section;
