import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Navigation, Footer } from "./components";
import { Configurator, Contact, Door, Garage, Home, Impressum } from "./views";
import styles from "./App.module.css";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Navigation />
        {/* 
				<FixedNavigation /> A <Switch> looks through its children <Route>s and
                  renders the first one that matches the current URL. */}
        <div className={styles.container}>
          <Switch className={styles.content}>
            <Route path="/impressum">
              <Impressum />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/config">
              <Configurator />
            </Route>
            <Route path="/door">
              <Door />
            </Route>
            <Route path="/garage">
              <Garage />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    );
  }
}
export default App;
