import React, { useEffect, useState } from "react";
import { Modal, makeStyles, createStyles, styled, Stepper, Step, StepLabel, StepContent, Button, Paper, Grid, Typography, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Items from "./Items";
import styles from "./WinConfigurator.module.css";
import Materials from "./Options/Materials";
import Profiles from "./Options/Profiles";
import ColorsInside from "./Options/ColorsInside";
import ColorsOutside from "./Options/ColorsOutside";
import WinType from "./Options/WinType";
import UpDownLight from "./Options/UpDownLight";
import OpenType from "./Options/OpenType";
import Glasses from "./Options/Glasses";
import WinSize from "./Options/WinSize";
import Config from "./materials.json";
import { sendMail } from '../../api';
import Swal from 'sweetalert2'

const MyButton = styled(Button)({
  background: "linear-gradient(to right, #4facfe 0%, #00f2fe 100%)",
  border: 0,
  borderRadius: 3,
  boxShadow: "0 3px 5px 2px rgba(0, 190, 255, .3)",
  color: "white",
  height: 48,
  padding: "0 30px",
  margin: "30px 0",
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    paper: {
      position: "absolute",
      width: `70%`,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

function WinConfigurator({ Heading, Text }) {
  const classes = useStyles();
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [activeStep, setActiveStep] = useState(0);
  const [WinItems, setWinItems] = useState([{ ID: 0 }]);
  const [currentWin, setCurrentWin] = useState(0);
  const [material, setMaterial] = useState("");
  const [profile, setProfile] = useState("");
  const [colorInside, setColorInside] = useState("");
  const [colorOutsite, setColorOutsite] = useState("");
  const [winType, setWinType] = useState("");
  const [upDownLight, setUpDownLight] = useState("");
  const [openType, setOpenType] = useState("");
  const [glasses, setGlasses] = useState("");
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [steps, setSteps] = useState(Config.plastic);

  useEffect(() => {
    if (material) {
      WinItems[currentWin].Material = material;
      if (material === "Kunstoff-Aluminium") {
        setSteps(Config.plasticAlu);
      }
      setMaterial("");
      handleNext();
    }
  }, [WinItems, currentWin, material, setMaterial]);

  useEffect(() => {
    if (profile) {
      WinItems[currentWin].Profil = profile;
      setProfile("");
      handleNext();
    }
  }, [WinItems, currentWin, profile, setProfile]);

  useEffect(() => {
    if (colorInside) {
      WinItems[currentWin].Farbe = colorInside;
      setColorInside("");
      handleNext();
    }
  }, [WinItems, currentWin, colorInside, setColorInside]);

  useEffect(() => {
    if (colorOutsite) {
      WinItems[currentWin].FarbeAussen = colorOutsite;
      setColorOutsite("");
      handleNext();
    }
  }, [WinItems, currentWin, colorOutsite, setColorOutsite]);

  useEffect(() => {
    if (winType) {
      WinItems[currentWin].Fenstertyp = winType;
      if (winType === "Sondertypen") {
        setSteps(Config.plasticSpezial);
      }
      setWinType("");
      handleNext();
    }
  }, [WinItems, currentWin, winType, setWinType]);

  useEffect(() => {
    if (upDownLight) {
      WinItems[currentWin].OberUnterLicht = upDownLight;
      setUpDownLight("");
      handleNext();
    }
  }, [WinItems, currentWin, upDownLight, setUpDownLight]);

  useEffect(() => {
    if (openType) {
      WinItems[currentWin].Oeffnungsart = openType;
      setOpenType("");
      handleNext();
    }
  }, [WinItems, currentWin, openType, setOpenType]);

  useEffect(() => {
    if (glasses) {
      WinItems[currentWin].Verglasung = glasses;
      setGlasses("");
      handleNext();
    }
  }, [WinItems, currentWin, glasses, setGlasses]);

  useEffect(() => {
    if (width > 0 && height > 0) {
      WinItems[currentWin].Hoehe = height;
      WinItems[currentWin].Breite = width;
      setHeight(0);
      setWidth(0);
      handleNext();
    }
  }, [WinItems, currentWin, width, setWidth, height, setHeight]);

  const setSizes = (x, y) => {
    setWidth(x);
    setHeight(y);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Materials setMaterial={setMaterial} />;
      case 1:
        return <Profiles setProfile={setProfile} material={WinItems[currentWin].Material} />;
      case 2:
        return <ColorsInside setColor={setColorInside} material={WinItems[currentWin].Material} />;
      case 3:
        return <ColorsOutside setColor={setColorOutsite} material={WinItems[currentWin].Material} />;
      case 4:
        return <WinType setType={setWinType} material={WinItems[currentWin].Material} />;
      case 5:
        return <UpDownLight setUpDownLigt={setUpDownLight} material={WinItems[currentWin].Fenstertyp} />;
      case 6:
        return <OpenType setOpenType={setOpenType} upDownLight={WinItems[currentWin].OberUnterLicht} winType={WinItems[currentWin].Fenstertyp} />;
      case 7:
        return <Glasses setGlasses={setGlasses} />;
      case 8:
        return <WinSize setSizes={setSizes} />;
      default:
        return "Unknown step";
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const addWindow = () => {
    setCurrentWin((currentWin) => currentWin + 1);
    setWinItems((WinItems) => [...WinItems, { ID: currentWin + 1 }]);
    setActiveStep(0);
  };

  const handleSubmit = () => {
    let Message= {
      'lastName': lastName,
      'firstName': firstName,
      'mail': mail,
      'phone': phone,
      'text': text,
      'window': WinItems
    };
    sendMail(Message);
    setOpen(false);
    Swal.fire({
      title: 'Erfolgreich',
      text: 'Ihre Nachricht wurde erfolgreich versendet. Wir melden uns schnellstmöglichs bei Ihnen!',
      icon: 'success',
      confirmButtonText: 'Danke'
    })
  };

  return (
    <div className={classes.root}>
      <div className={styles.items}>
        <div className={styles.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={styles.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant='h4' gutterBottom>
                      {Heading}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      {Text}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
      <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map(({ label, content }, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {getStepContent(content)}
              <div className={classes.actionsContainer}>
                <div>
                  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    Zurück
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      <div className={styles.items}>
        <div className={styles.container}>
          <Grid container spacing={3} justify='center'>
            {WinItems[0]
              ? WinItems.map(({ ID, Material, Profil, Farbe, FarbeAussen, Fenstertyp, OberUnterLicht, Oeffnungsart, Breite, Hoehe }) => (
                  <Items key={ID} Material={Material} Profil={Profil} Farbe={Farbe} FarbeAussen={FarbeAussen} Fenstertyp={Fenstertyp} OberUnterLicht={OberUnterLicht} Oeffnungsart={Oeffnungsart} Breite={Breite} Hoehe={Hoehe} />
                ))
              : null}
          </Grid>
        </div>
      </div>

      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <MyButton onClick={() => setOpen(true)} className={classes.button}>
            Send
          </MyButton>
          <MyButton onClick={addWindow} className={classes.button}>
            Weiteres Fenster
          </MyButton>
        </Paper>
      )}

      <Modal open={open} onClose={() => setOpen(false)}>
        <div style={modalStyle} className={classes.paper}>
          <div className={styles.items}>
            <div className={styles.container}>
              <ValidatorForm onSubmit={handleSubmit} onError={(errors) => console.log(errors)}>
                <Grid container justify='center'>
                  <Grid item xs={12}>
                    <Typography variant='h2'>Kontaktform</Typography>
                    <Typography varian='body1' paragraph={true}>
                      Bei Fragen können Sie sich gerne über unser Kontaktformular an uns wenden. Wir erstellen Ihnen außerdem innerhalb von 7 Tagen Ihr individuelles Angebot.
                    </Typography>
                    <Grid container spacing={3} direction='row' justify='center' alignItems='center'>
                      <Grid item xs={12} md={4}>
                        <TextField fullWidth={true} required id='outlined-basic' label='Name' variant='outlined' type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField fullWidth={true} required id='outlined-basic' label='Vorname' variant='outlined' type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} direction='row' justify='center' alignItems='center'>
                      <Grid item xs={12} md={4}>
                        <TextValidator
                          fullWidth={true}
                          id='outlined-basic'
                          label='Telefonnummer'
                          variant='outlined'
                          type='tel'
                          validators={["matchRegexp:^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$"]}
                          errorMessages={["Bitte verwenden Sie nur Zahlen."]}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextValidator
                          label='E-Mail'
                          required
                          fullWidth={true}
                          onChange={(e) => setMail(e.target.value)}
                          name='mail'
                          value={mail}
                          validators={["required", "isEmail"]}
                          errorMessages={["Bitte füllen Sie dieses Feld aus.", "E-Mail ist nicht korrekt."]}
                          variant='outlined'
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} direction='row' justify='center' alignItems='center'>
                      <Grid item xs={12} md={8}>
                        <TextField id='outlined-multiline-static' label='Ihre Nachricht' required fullWidth={true} multiline rows={4} variant='outlined' value={text} onChange={(e) => setText(e.target.value)} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} direction='row' justify='center' alignItems='center'></Grid>
                    <Grid container spacing={3} direction='row-reverse' justify='center' alignItems='flex-end'>
                      <Grid item xs={12} md={8}>
                        <MyButton type='submit'>Senden</MyButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default WinConfigurator;
