import React from "react";
import { Card, CardActionArea, CardContent, Grid, Typography } from "@material-ui/core";
import styles from "../WinConfigurator.module.css";
import {
  Weiss,
  Anthrazitgrau,
  Alpha,
  Beta,
  DB703,
  Delta,
  Epsilion,
  Eta,
  Fenstergrau,
  Gamma,
  Graualu,
  Lehmbraun,
  Lichtgrau,
  Moosgruen,
  Ockerbraun,
  Perlweiss,
  Rostiges,
  Rubinrot,
  Sandgelb,
  Schiefergrau,
  Schokoladenbraun,
  Silbergrau,
  Stahlblau,
  Taubenblau,
  Weissaluminium,
} from "./Colors/Outside";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  root: {
    textAlign: "center",
    maxWidth: 345,
    minWidth: 245,
    margin: 10,
  },
  media: {
    maxHeight: 190,
    objectFit: "contain",
  },
});

function ColorsOutside({ setColor, material }) {
  const classes = useStyles();

  let items = [{}];
  if (material === "Kunstoff-Aluminium") {
    items = [
      {
        color: "Weiß",
        Image: Weiss,
      },
      {
        color: "Lichtgrau",
        Image: Lichtgrau,
      },
      {
        color: "Anthrazitgrau",
        Image: Anthrazitgrau,
      },
      {
        color: "Moosgrün",
        Image: Moosgruen,
      },
      {
        color: "Ockerbraun",
        Image: Ockerbraun,
      },
      {
        color: "Lehmbraun",
        Image: Lehmbraun,
      },
      {
        color: "Schokoladenbraun",
        Image: Schokoladenbraun,
      },
      {
        color: "Perlweiß",
        Image: Perlweiss,
      },
      {
        color: "Weißaluminium Metallic",
        Image: Weissaluminium,
      },
      {
        color: "Gamma Metallic matt",
        Image: Gamma,
      },
      {
        color: "Sandgelb",
        Image: Sandgelb,
      },
      {
        color: "Rubinrot",
        Image: Rubinrot,
      },
      {
        color: "Taubenblau",
        Image: Taubenblau,
      },
      {
        color: "Silbergrau",
        Image: Silbergrau,
      },
      {
        color: "Fenstergrau",
        Image: Fenstergrau,
      },
      {
        color: "Alpha Metallic matt",
        Image: Alpha,
      },
      {
        color: "Beta Metallic matt",
        Image: Beta,
      },
      {
        color: "Graualuminium Metallic",
        Image: Graualu,
      },
      {
        color: "Rostiges Eisen Feinstruktur matt",
        Image: Rostiges,
      },
      {
        color: "Delta Metallic matt",
        Image: Delta,
      },
      {
        color: "Schiefergrau",
        Image: Schiefergrau,
      },
      {
        color: "DB 703 Metallic matt",
        Image: DB703,
      },
      {
        color: "Eta Metallic matt",
        Image: Eta,
      },
      {
        color: "Epsilion Metallic matt",
        Image: Epsilion,
      },
      {
        color: "Stalblau",
        Image: Stahlblau,
      },
    ];
  }

  return (
    <Grid className={styles.items}>
      <Grid className={styles.containerItems}>
        <Grid container spacing={3} justify='center'>
          {items.map((item) => (
            <Card className={classes.root} key={item.color}>
              <CardActionArea onClick={() => setColor(item.color)}>
                <CardContent>
                  <img className={classes.media} src={item.Image} alt={item.color} />
                  <Typography gutterBottom variant='subtitle1' component='h2'>
                    {item.color}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ColorsOutside;
