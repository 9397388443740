import React from "react";
import styles from "./Ratings.module.css";
import Carousel from "react-material-ui-carousel";
import ItemRating from "./Rating/ItemRating";
import ImageOne from "../../images/Rating_1.jpg";
import ImageTwo from "../../images/Rating_2.jpg";
import ImageThree from "../../images/Rating_3.jpg";

const Ratings = () => {
  var items = [
    {
      Name: "Thompson",
      Image: ImageOne,
      Description: "Übersichtlich. Schnell und unkompliziert. Danke!",
    },
    {
      Name: "Schmidt",
      Image: ImageTwo,
      Description:
        "sehr unkomplizierte Abwicklung. Ich konnte aus verschiedenen Variationen auswählen. Die Fenster wurden schnell geliefert und die Monteure haben tolle Arbeit geleistet.",
    },
    {
      Name: "Petersen",
      Image: ImageThree,
      Description:
        "Alles bestens! Wir haben schon länger nach einer Möglichkeit gesucht unsere Fenster nach eigenen Wünschen online zu erstellen. Mit dem Konfigurator klappt das sehr gut, da man sich nur durchklicken muss.",
    },
  ];

  return (
    <div className={styles.items}>
      <div className={styles.container}>
        <Carousel animation="slide">
          {items.map((item, i) => (
            <ItemRating key={i} item={item} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Ratings;
