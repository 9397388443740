import React, {forwardRef} from 'react'
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import styles from "./WinConfigurator.module.css";

const Items = forwardRef(({Material, Profil, Farbe, FarbeAussen,Fenstertyp, OberUnterLicht, Oeffnungsart, Breite, Hoehe}, ref) => {
    var type = '';
    if(Fenstertyp === 'Sondertypen'){
        type = (<Typography>Sondertyp: {OberUnterLicht}</Typography>)
    }else{
        type = (<Typography>Ober-/Unterlicht: {OberUnterLicht}</Typography>);
    }

    return (
        <Grid item component={Card} xs={12} md={3} className={styles.card} ref={ref}>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Fenster
                </Typography>
                <Typography>Material: {Material}</Typography>
                <Typography>Profil: {Profil}</Typography>
                <Typography>Farbe: {Farbe}</Typography>
                {FarbeAussen?(<Typography>Farbe Außen: {FarbeAussen}</Typography>): null}
                <Typography>Fenstertyp: {Fenstertyp}</Typography>
                {type}
                <Typography>Öffnungsart: {Oeffnungsart}</Typography>
                <Typography>Breite in mm: {Breite}</Typography>
                <Typography>Höhe in mm: {Hoehe}</Typography>
            </CardContent>
        </Grid>
    );
});

export default Items
