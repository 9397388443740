import React from "react";
import { Typography, Card, CardActionArea, CardContent, Grid } from "@material-ui/core";
import ID4000 from "../images/ID4000_Profil.jpeg";
import ID8000 from "../images/ID8000TS_Profil.png";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../WinConfigurator.module.css";

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    maxWidth: 345,
    maxHeight: 250,
    minWidth: 245,
    margin: 10,
  },
  media: {
    maxHeight: 190,
    objectFit: "contain",
  },
});

function Materials({ setMaterial }) {
  const classes = useStyles();

  let items = [
    {
      Material: "Kunstoff",
      Image: ID4000,
    },
    {
      Material: "Kunstoff-Aluminium",
      Image: ID8000,
    },
  ];

  return (
    <Grid className={styles.items}>
      <Grid className={styles.containerItems}>
        <Grid container spacing={3} justify='center'>
          {items.map((item) => (
            <Card className={classes.root} key={item.Material}>
              <CardActionArea onClick={() => setMaterial(item.Material)}>
                <CardContent>
                  <img className={classes.media} src={item.Image} alt={item.Material} />
                  <Typography gutterBottom variant='subtitle1' component='h2'>
                    {item.Material}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Materials;
