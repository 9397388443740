import axios from 'axios';

const url = 'https://fensterundco.de/api/public/send-mail';

export const sendMail = (data) => {
  axios({
    method: 'post',
    body: JSON.stringify(data),
    url: `${url}`,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data,
  })
    .then((result) => {
    })
    .catch((error) => {
    });
};
