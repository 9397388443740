import React from "react";
import { Card, CardActionArea, CardContent, Grid, Typography } from "@material-ui/core";
import styles from "../WinConfigurator.module.css";
import { Einteilig, Zweitlig, Dreitelig, Sondertypen } from "./WinTypes/winTypes";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    maxWidth: 345,
    minWidth: 245,
    margin: 10,
  },
  media: {
    maxHeight: 190,
    objectFit: "contain",
  },
});

function WinType({ setType, material }) {
  const classes = useStyles();

  let items = [{}];
  if (material === "Kunstoff") {
    items = [
      {
        type: "Einteilig",
        Image: Einteilig,
      },
      {
        type: "Zweiteilig",
        Image: Zweitlig,
      },
      {
        type: "Dreiteilig",
        Image: Dreitelig,
      },
      {
        type: "Sondertypen",
        Image: Sondertypen,
      },
    ];
  } else if (material === "Kunstoff-Aluminium") {
    items = [
      {
        type: "Einteilig",
        Image: Einteilig,
      },
      {
        type: "Zweiteilig",
        Image: Zweitlig,
      },
      {
        type: "Dreiteilig",
        Image: Dreitelig,
      },
    ];
  }
  return (
    <Grid className={styles.items}>
      <Grid className={styles.containerItems}>
        <Grid container spacing={3} justify='center'>
          {items.map((item) => (
            <Card className={classes.root} key={item.type}>
              <CardActionArea onClick={() => setType(item.type)}>
                <CardContent>
                  <img className={classes.media} src={item.Image} alt={item.type} />
                  <Typography gutterBottom variant='subtitle1' component='h2'>
                    {item.type}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WinType;
