import React from 'react';

import { ContactForm } from '../components';
class Contact extends React.Component {
	render() {
		return (
			<div style={{ width: '100%' }}>
				<ContactForm />
			</div>
		);
	}
}
export default Contact;
