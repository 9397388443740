import React from "react";
import { WinConfigurator } from "../components";

class Configurator extends React.Component {
  render() {
    return (
      <div style={{ width: "100%" }}>
        <WinConfigurator
          Heading={"Fensterkonfigurator"}
          Text={
            "Unser spezieller Fensterkonfigurator ist kinderleicht und mit ein paar Klicks haben sie am Ende ihre Wunschfenster erstellt. Es gibt verschiedene Optionen zur Auswahl. Sie können die Öffnungsvariante bestimmen, wie viele Flügel ihr Fenster haben soll, welche Verglasung, sowie sicherheitsrelevante Eigenschaften können ausgewählt werden."
          }
        />
      </div>
    );
  }
}
export default Configurator;
