import React from "react";
import styles from "./HeroImg.module.css";

export default function HeroImg() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 771.75 602.96'>
      <defs>
        <linearGradient
          id='Unbenannter_Verlauf_14'
          x1='582.69'
          y1='295.82'
          x2='582.69'
          y2='391.22'
          gradientTransform='matrix(1, 0, 0, -1, 0, 560.31)'
          gradientUnits='userSpaceOnUse'>
          <stop offset='0.35' stop-color='#fff' />
          <stop offset='0.38' stop-color='#e5e3e2' />
          <stop offset='0.47' stop-color='#9b9290' />
          <stop offset='0.55' stop-color='#655754' />
          <stop offset='0.6' stop-color='#44322f' />
          <stop offset='0.63' stop-color='#372421' />
          <stop offset='0.73' stop-color='#291513' />
          <stop offset='0.87' stop-color='#1b0806' />
          <stop offset='1' stop-color='#170302' />
        </linearGradient>
        <linearGradient
          id='Unbenannter_Verlauf_8'
          x1='582.5'
          y1='300.78'
          x2='582.5'
          y2='-42.54'
          gradientTransform='matrix(1, 0, 0, -1, 0, 560.31)'
          gradientUnits='userSpaceOnUse'>
          <stop offset='0.1' stop-color='#b0b0b2' />
          <stop offset='0.2' stop-color='#b4b4b5' />
          <stop offset='0.44' stop-color='#b8b9b9' />
          <stop offset='1' stop-color='#bfbbb9' />
        </linearGradient>
        <linearGradient
          id='Unbenannter_Verlauf_12'
          x1='376.86'
          y1='1529.38'
          x2='376.86'
          y2='1478.46'
          gradientTransform='matrix(0, 1, 1, 0, -790.99, 213.79)'
          gradientUnits='userSpaceOnUse'>
          <stop offset='0' stop-color='#bbb5b3' />
          <stop offset='1' stop-color='#787472' />
        </linearGradient>
        <linearGradient
          id='Unbenannter_Verlauf_15'
          x1='582.5'
          y1='287.79'
          x2='582.5'
          y2='330.11'
          gradientTransform='matrix(1, 0, 0, -1, 0, 560.31)'
          gradientUnits='userSpaceOnUse'>
          <stop offset='0.35' stop-color='#fff' />
          <stop offset='0.38' stop-color='#efefef' />
          <stop offset='0.5' stop-color='#b3b3b3' />
          <stop offset='0.58' stop-color='#8e8e8e' />
          <stop offset='0.63' stop-color='gray' />
          <stop offset='0.67' stop-color='#757575' />
          <stop offset='0.79' stop-color='#5f5f5f' />
          <stop offset='0.9' stop-color='#525252' />
          <stop offset='1' stop-color='#4d4d4d' />
        </linearGradient>
        <linearGradient
          id='Unbenannter_Verlauf_23'
          x1='222.25'
          y1='830.16'
          x2='226.65'
          y2='830.16'
          gradientTransform='translate(-203 -784.42)'
          gradientUnits='userSpaceOnUse'>
          <stop offset='0' stop-color='#cb9314' />
          <stop offset='0.25' stop-color='#e3a829' />
          <stop offset='0.49' stop-color='#ffc041' />
          <stop offset='0.59' stop-color='#fabb3c' />
          <stop offset='0.73' stop-color='#ebae2f' />
          <stop offset='0.87' stop-color='#d29819' />
          <stop offset='1' stop-color='#b57e00' />
        </linearGradient>
        <linearGradient
          id='Unbenannter_Verlauf_24'
          x1='224.37'
          y1='384.27'
          x2='224.37'
          y2='396.05'
          gradientTransform='matrix(1, 0, 0, -1, -203, 441.31)'
          gradientUnits='userSpaceOnUse'>
          <stop offset='0' />
          <stop offset='0.49' stop-color='#7a6b6b' />
          <stop offset='1' stop-color='#f5d7d7' />
        </linearGradient>
      </defs>
      <g className={styles.Garage}>
        <g data-name='Layer 2'>
          <path
            d='M393.65 194.04H771.75V601.31H393.65z'
            className={styles.cls1}></path>
          <path
            d='M397.75 198.46H767.64V596.89H397.75z'
            className={styles.cls2}></path>
          <path
            d='M399.61 200.46H765.79V594.88H399.61z'
            className={styles.cls3}></path>
          <path
            d='M401.45 202.44H763.95V592.9H401.45z'
            className={styles.cls1}></path>
          <path
            d='M403.27 204.41H762.12V590.9399999999999H403.27z'
            className={styles.cls2}></path>
          <path
            d='M405.07 206.35H760.31V589H405.07z'
            className={styles.cls4}></path>
          <path
            d='M406.86 208.27H758.53V587.07H406.86z'
            className={styles.cls2}></path>
          <path
            d='M407.59 209.06H757.8V234.76H407.59z'
            className={styles.cls5}></path>
          <path
            d='M407.59 209.06H757.8V214.03H407.59z'
            className={styles.cls6}></path>
          <path
            d='M407.39 224.52H757.5999999999999V601.4300000000001H407.39z'
            className={styles.cls7}></path>
          <path
            d='M407.39 278.16H757.5999999999999V279.76000000000005H407.39z'
            className={styles.cls8}></path>
          <path
            d='M407.39 248.06H757.5999999999999V249.66H407.39z'
            className={styles.cls8}></path>
          <path
            d='M407.39 307.2H757.5999999999999V308.8H407.39z'
            className={styles.cls8}></path>
          <path
            d='M407.39 336.77H757.5999999999999V338.37H407.39z'
            className={styles.cls8}></path>
          <path
            d='M407.39 365.81H757.5999999999999V367.41H407.39z'
            className={styles.cls8}></path>
          <path
            d='M407.39 396.87H757.5999999999999V398.47H407.39z'
            className={styles.cls8}></path>
          <path
            d='M407.39 427.52H757.5999999999999V429.12H407.39z'
            className={styles.cls8}></path>
          <path
            d='M407.39 456.09H757.5999999999999V457.69H407.39z'
            className={styles.cls8}></path>
          <path
            d='M407.39 486.42H757.5999999999999V488.02000000000004H407.39z'
            className={styles.cls8}></path>
          <path
            d='M407.39 515.12H757.5999999999999V516.72H407.39z'
            className={styles.cls8}></path>
          <path
            d='M407.39 546.15H757.5999999999999V547.75H407.39z'
            className={styles.cls8}></path>
          <path
            d='M407.39 576.35H757.5999999999999V577.95H407.39z'
            className={styles.cls8}></path>
          <path
            d='M702.71 591.74c0 2.53 6.74 2 13.9 2h22.69c3.27 0 4.7 4.34 4.7 4.34h2.66v-14.85H744s-1.44 4.34-4.7 4.34h-22.69c-7.15 0-13.9-.54-13.9 2v2.18z'
            className={styles.cls9}></path>
          <path
            d='M709.81 591.74c0 2.53 6 2 12.29 2h20c2.89 0 4.16 4.34 4.16 4.34h2.35v-14.85h-2.35s-1.27 4.34-4.16 4.34h-20c-6.33 0-12.29-.54-12.29 2v2.18z'
            className={styles.cls10}></path>
          <path
            d='M464.68 591.74c0 2.53-6.74 2-13.9 2h-22.66c-3.27 0-4.7 4.34-4.7 4.34h-2.66v-14.85h2.66s1.44 4.34 4.7 4.34h22.69c7.15 0 13.9-.54 13.9 2v2.18z'
            className={styles.cls11}></path>
          <path
            d='M457.58 591.74c0 2.53-6 2-12.29 2h-20.07c-2.89 0-4.16 4.34-4.16 4.34h-2.35v-14.85h2.35s1.27 4.34 4.16 4.34h20.06c6.33 0 12.29-.54 12.29 2v2.18z'
            className={styles.cls12}></path>
          <path
            d='M754.21 224.52H758.0600000000001V601.4300000000001H754.21z'
            className={styles.cls13}></path>
          <path
            d='M407.39 224.52H411.24V601.4300000000001H407.39z'
            className={styles.cls14}></path>
          <g className={styles.cls15}>
            <path
              d='M407.39 224.52H757.5999999999999V251.60000000000002H407.39z'
              className={styles.cls16}></path>
          </g>
          <path
            d='M758.07 576.17H771.75V602.9599999999999H758.07z'
            className={styles.cls17}></path>
          <path
            d='M393.25 576.17H407.39V602.9599999999999H393.25z'
            className={styles.cls17}></path>
          <path
            d='M397.31 576.17H401.34V602.9599999999999H397.31z'
            className={styles.cls18}></path>
          <path
            d='M764.14 576.17H768.17V602.9599999999999H764.14z'
            className={styles.cls18}></path>
        </g>
      </g>
      <g className={styles.Door}>
        <path
          d='M393.51 55.74c-1.13 0-2.25 0-3.37.09a106.46 106.46 0 01103.1 106.4v340.56H500V162.23A106.48 106.48 0 00393.51 55.74z'
          className={styles.cls19}></path>
        <path
          d='M390.13 55.83A106.46 106.46 0 00287 162.23v340.56h206.24V162.23a106.46 106.46 0 00-103.11-106.4z'
          className={styles.cls20}></path>
        <path
          d='M501.93 504.74H285.07V162.23a108.43 108.43 0 01216.86 0zm-213-3.9H498V162.23a104.55 104.55 0 00-209.08 0v338.61z'
          className={styles.cls21}></path>
        <path
          d='M402.14 72.64a89.76 89.76 0 0121.6 2.64 90.08 90.08 0 00-120.36 84.89V488.5H312V162.76a90.22 90.22 0 0190.14-90.12z'
          className={styles.cls20}></path>
        <path
          d='M483.63 160.16a90.27 90.27 0 00-59.89-84.89A90.11 90.11 0 00312 162.76V488.5h171.63z'
          className={styles.cls19}></path>
        <path
          d='M485.57 490.45H301.42V160.16a92.08 92.08 0 11184.15-1.92v1.92zm-180.25-3.9h176.35V160.16a88.18 88.18 0 10-176.35 0z'
          className={styles.cls21}></path>
        <path
          d='M318.99 363.39H466.9V459.19H318.99z'
          className={styles.cls22}></path>
        <path
          d='M318.99 196.26H466.9V346.69H318.99z'
          className={styles.cls22}></path>
        <path
          d='M327.32 205.55H375.38V338.14H327.32z'
          className={styles.cls22}></path>
        <path
          d='M374.56 331.68L373.43 331.68 373.43 206.77 368.87 206.77 368.87 331.68 328.38 331.68 328.38 336.19 368.87 336.19 368.87 337.21 373.43 337.21 373.43 336.19 374.56 336.19 374.56 331.68z'
          className={styles.cls23}></path>
        <path
          d='M374.56 207.5L333.81 207.5 333.81 206.77 329.26 206.77 329.26 207.5 328.38 207.5 328.38 212.01 329.26 212.01 329.26 337.21 333.5 331.37 333.81 212.01 369 212.01 374.56 207.5z'
          className={styles.cls19}></path>
        <path
          d='M377.32 340.1h-52V203.61h52zm-48.08-3.91h44.16V207.51h-44.16z'
          className={styles.cls21}></path>
        <path
          d='M408.63 205.55H456.69V338.14H408.63z'
          className={styles.cls22}></path>
        <path
          d='M455.88 331.68L454.74 331.68 454.74 206.77 450.19 206.77 450.19 331.68 409.7 331.68 409.7 336.19 450.19 336.19 450.19 337.21 454.74 337.21 454.74 336.19 455.88 336.19 455.88 331.68z'
          className={styles.cls23}></path>
        <path
          d='M455.88 207.5L415.13 207.5 415.13 206.77 410.57 206.77 410.57 207.5 409.7 207.5 409.7 212.01 410.57 212.01 410.57 337.21 415.38 331.37 415.13 212.01 450.17 212.01 455.88 207.5z'
          className={styles.cls19}></path>
        <path
          d='M458.64 340.1h-52V203.61h52zm-48.07-3.9h44.16V207.51h-44.16v128.68z'
          className={styles.cls21}></path>
        <path
          d='M327.32 372.78H375.38V450.33H327.32z'
          className={styles.cls22}></path>
        <path
          d='M377.32 452.29h-52v-81.45h52v81.45zm-48.08-3.92h44.16v-73.63h-44.16z'
          className={styles.cls21}></path>
        <path
          d='M341.82 398.7H360.88V424.41999999999996H341.82z'
          className={styles.cls20}></path>
        <path
          d='M341.32 220.2H360.38V324.37H341.32z'
          className={styles.cls20}></path>
        <path
          d='M422.82 220.2H441.88V324.37H422.82z'
          className={styles.cls20}></path>
        <path
          d='M329.37 374.74L341.92 398.7 360.98 398.7 373.52 374.74 329.37 374.74z'
          className={styles.cls23}></path>
        <path
          d='M329.37 448.38L341.92 424.42 360.98 424.42 373.52 448.38 329.37 448.38z'
          className={styles.cls19}></path>
        <path
          d='M408.63 372.79H456.69V450.34000000000003H408.63z'
          className={styles.cls22}></path>
        <path
          d='M410.57 374.74L423.13 398.7 442.19 398.7 454.74 374.74 410.57 374.74z'
          className={styles.cls23}></path>
        <path
          d='M410.57 448.38L423.13 424.42 442.19 424.42 454.74 448.38 410.57 448.38z'
          className={styles.cls19}></path>
        <path
          d='M458.64 452.29h-52v-81.45h52v81.45zm-48.07-3.92h44.16v-73.63h-44.16z'
          className={styles.cls21}></path>
        <path
          d='M423.13 398.7H442.19V424.41999999999996H423.13z'
          className={styles.cls20}></path>
        <path
          d='M464.89 175.56c0-44.9-27.31-80.1-73.74-80.1-41.7 0-71 35.87-71.42 80.31v6.83h145.16v-6.83z'
          className={styles.cls22}></path>
        <path
          d='M419.51 110.79a62.4 62.4 0 00-27.21-6.27h-.56a63 63 0 00-62.31 62.85v6.22h125.75v-6.22a62.88 62.88 0 00-35.67-56.58z'
          className={styles.cls24}></path>
        <path
          d='M392.32 104.51h-.58L358 173.58h30.81l30.65-62.83a62.28 62.28 0 00-27.14-6.24zM364.61 111l-30.54 62.59h13.33l33.16-68a62.2 62.2 0 00-15.95 5.41z'
          className={styles.cls25}></path>
        <path
          d='M467.88 288.32H480.71V367.45H467.88z'
          className={styles.cls23}></path>
        <path
          d='M479.73 287.37h-12.84v81.08h14.78v-81.08h-1.94zm0 79.13h-10.88v-77.2h10.88v77.2z'
          className={styles.cls21}></path>
        <circle
          cx='474.29'
          cy='312.35'
          r='3.52'
          className={styles.cls21}></circle>
        <circle
          cx='474.29'
          cy='356.37'
          r='3.52'
          className={styles.cls26}></circle>
        <circle
          cx='474.29'
          cy='356.37'
          r='3.52'
          className={styles.cls21}></circle>
        <rect
          width='4.35'
          height='46.2'
          x='472.12'
          y='311.1'
          className={styles.cls20}
          rx='0.51'></rect>
        <path d='M476.07 298.91a1.78 1.78 0 10-3.56 0 1.75 1.75 0 00.85 1.49l-.53 1.67h2.9l-.61-1.61a1.79 1.79 0 00.95-1.55z'></path>
        <path
          d='M389.92 101.37H395.92V177.7H389.92z'
          className={styles.cls22}></path>
        <path
          d='M373.08 139.53H449.40999999999997V145.53H373.08z'
          className={styles.cls22}
          transform='rotate(-60 411.238 142.535)'></path>
        <path
          d='M387.89 155.31H462.15999999999997V161.31H387.89z'
          className={styles.cls22}
          transform='rotate(-25.29 425.02 158.298)'></path>
        <path
          d='M369.42 104.35H375.42V180.68H369.42z'
          className={styles.cls22}
          transform='rotate(-35.74 372.415 142.516)'></path>
        <path
          d='M357.17 121.66H363.17V195.93H357.17z'
          className={styles.cls22}
          transform='rotate(-65.38 360.169 158.8)'></path>
        <path
          d='M372.42 174.29a19.91 19.91 0 1139.82-.89v.89'
          className={styles.cls22}></path>
        <path
          d='M379.24 174.12a12.59 12.59 0 0125.18 0'
          className={styles.cls20}></path>
        <circle
          cx='474.29'
          cy='312.35'
          r='2.36'
          className={styles.cls21}></circle>
        <circle
          cx='474.29'
          cy='356.18'
          r='2.36'
          className={styles.cls21}></circle>
      </g>
      <g className={styles.Window}>
        <g data-name='Layer 3'>
          <path
            d='M0 0v393h393V0zm359 369H31V23h328z'
            className={styles.cls20}></path>
          <path
            d='M22 13v364h173V13zm154 349H39V31h137zM196 13v364h173V13zm154 349H213V31h137z'
            className={styles.cls23}></path>
          <path
            d='M41.37 29.51H106.37V139.81H41.37z'
            className={styles.cls27}></path>
          <path
            d='M106.37 29.51L106.37 139.81 50.53 141.01'
            className={styles.cls28}></path>
          <path
            d='M41.37 144.01H106.37V251.33999999999997H41.37z'
            className={styles.cls27}></path>
          <path
            d='M106.37 144.01L106.37 251.34 50.53 252.51'
            className={styles.cls28}></path>
          <path
            d='M41.37 252.01H106.37V359.34H41.37z'
            className={styles.cls27}></path>
          <path
            d='M106.37 252.01L106.37 359.34 50.53 360.51'
            className={styles.cls28}></path>
          <path
            d='M109.37 29.51H174.37V139.81H109.37z'
            className={styles.cls27}></path>
          <path
            d='M174.37 29.51L174.37 139.81 118.53 141.01'
            className={styles.cls28}></path>
          <path
            d='M109.37 144.01H174.37V251.33999999999997H109.37z'
            className={styles.cls27}></path>
          <path
            d='M174.37 144.01L174.37 251.34 118.53 252.51'
            className={styles.cls28}></path>
          <path
            d='M109.37 252.01H174.37V359.34H109.37z'
            className={styles.cls27}></path>
          <path
            d='M174.37 252.01L174.37 359.34 118.53 360.51'
            className={styles.cls28}></path>
          <path
            d='M216.87 29.51H281.87V139.81H216.87z'
            className={styles.cls27}></path>
          <path
            d='M281.87 29.51L281.87 139.81 226.03 141.01'
            className={styles.cls28}></path>
          <path
            d='M216.87 144.01H281.87V251.33999999999997H216.87z'
            className={styles.cls27}></path>
          <path
            d='M281.87 144.01L281.87 251.34 226.03 252.51'
            className={styles.cls28}></path>
          <path
            d='M216.87 252.01H281.87V359.34H216.87z'
            className={styles.cls27}></path>
          <path
            d='M281.87 252.01L281.87 359.34 226.03 360.51'
            className={styles.cls28}></path>
          <path
            d='M284.87 29.51H349.87V139.81H284.87z'
            className={styles.cls27}></path>
          <path
            d='M349.87 29.51L349.87 139.81 294.03 141.01'
            className={styles.cls28}></path>
          <path
            d='M284.87 144.01H349.87V251.33999999999997H284.87z'
            className={styles.cls27}></path>
          <path
            d='M349.87 144.01L349.87 251.34 294.03 252.51'
            className={styles.cls28}></path>
          <path
            d='M284.87 252.01H349.87V359.34H284.87z'
            className={styles.cls27}></path>
          <path
            d='M349.87 252.01L349.87 359.34 294.03 360.51'
            className={styles.cls28}></path>
          <ellipse
            cx='186.87'
            cy='196.01'
            className={styles.cls29}
            rx='2.04'
            ry='3.83'></ellipse>
          <ellipse
            cx='186.53'
            cy='196.01'
            className={styles.cls21}
            rx='4'
            ry='7.5'></ellipse>
          <ellipse
            cx='186.53'
            cy='196.01'
            className={styles.cls29}
            rx='2.04'
            ry='3.83'></ellipse>
          <ellipse
            cx='206.3'
            cy='196.01'
            className={styles.cls29}
            rx='2.04'
            ry='3.83'></ellipse>
          <ellipse
            cx='205.97'
            cy='196.01'
            className={styles.cls21}
            rx='4'
            ry='7.5'></ellipse>
          <ellipse
            cx='205.97'
            cy='196.01'
            className={styles.cls29}
            rx='2.04'
            ry='3.83'></ellipse>
          <path
            d='M22.37 12.84H368.7V376.84H22.37z'
            className={styles.cls30}></path>
          <path
            d='M24 35.1h-4.9a.11.11 0 00-.11.11v21.06a.11.11 0 00.11.11H24'
            className={styles.cls31}></path>
          <path
            d='M20 46v9c0 1 2.74 1 2.74 0v-9z'
            className={styles.cls32}></path>
          <path
            d='M24.1 45.21L18.98 45.23 18.98 45.76 24.1 45.76'
            className={styles.cls33}></path>
          <path
            d='M24 34.64h-4.9a.11.11 0 00-.11.11V45.5h5.12'
            className={styles.cls34}></path>
          <path
            d='M22.74 45.38V36c0-1-2.74-1-2.74 0v9.36h2.74z'
            className={styles.cls35}></path>
          <path
            d='M24 176.1h-4.9a.11.11 0 00-.11.11v21.06a.11.11 0 00.11.11H24'
            className={styles.cls36}></path>
          <path
            d='M20 187v9c0 .95 2.74.95 2.74 0v-9z'
            className={styles.cls37}></path>
          <path
            d='M24.1 186.21L18.98 186.23 18.98 186.76 24.1 186.76'
            className={styles.cls33}></path>
          <path
            d='M24 175.64h-4.9a.11.11 0 00-.11.11v10.75h5.12'
            className={styles.cls38}></path>
          <path
            d='M22.74 186.38V177c0-.95-2.74-.95-2.74 0v9.36h2.74z'
            className={styles.cls39}></path>
          <path
            d='M24 337.09h-4.9a.11.11 0 00-.11.11v21.06a.11.11 0 00.11.11H24'
            className={styles.cls40}></path>
          <path
            d='M20 348v9c0 .95 2.74.95 2.74 0v-9z'
            className={styles.cls41}></path>
          <path
            d='M24.1 347.19L18.98 347.21 18.98 347.75 24.1 347.75'
            className={styles.cls33}></path>
          <path
            d='M24 336.63h-4.9a.11.11 0 00-.11.11v10.75h5.12'
            className={styles.cls42}></path>
          <path
            d='M22.74 347.36V338c0-.95-2.74-.95-2.74 0v9.36z'
            className={styles.cls43}></path>
          <path
            d='M366.58 35.1h4.9a.11.11 0 01.11.11v21.06a.11.11 0 01-.11.11h-4.9'
            className={styles.cls44}></path>
          <path
            d='M370.58 46v9c0 1-2.74 1-2.74 0v-9z'
            className={styles.cls45}></path>
          <path
            d='M366.47 45.21L371.59 45.23 371.59 45.76 366.47 45.76'
            className={styles.cls33}></path>
          <path
            d='M366.58 34.64h4.9a.11.11 0 01.11.11V45.5h-5.12'
            className={styles.cls46}></path>
          <path
            d='M367.84 45.38V36c0-1 2.74-1 2.74 0v9.36h-2.74z'
            className={styles.cls47}></path>
          <path
            d='M366.58 176.1h4.9a.11.11 0 01.11.11v21.06a.11.11 0 01-.11.11h-4.9'
            className={styles.cls48}></path>
          <path
            d='M370.58 187v9c0 .95-2.74.95-2.74 0v-9z'
            className={styles.cls49}></path>
          <path
            d='M366.47 186.21L371.59 186.23 371.59 186.76 366.47 186.76'
            className={styles.cls33}></path>
          <path
            d='M366.58 175.64h4.9a.11.11 0 01.11.11v10.75h-5.12'
            className={styles.cls50}></path>
          <path
            d='M367.84 186.38V177c0-.95 2.74-.95 2.74 0v9.36h-2.74z'
            className={styles.cls51}></path>
          <path
            d='M366.58 337.09h4.9a.11.11 0 01.11.11v21.06a.11.11 0 01-.11.11h-4.9'
            className={styles.cls52}></path>
          <path
            d='M370.58 348v9c0 .95-2.74.95-2.74 0v-9z'
            className={styles.cls53}></path>
          <path
            d='M366.47 347.19L371.59 347.21 371.59 347.75 366.47 347.75'
            className={styles.cls33}></path>
          <path
            d='M366.58 336.63h4.9a.11.11 0 01.11.11v10.75h-5.12'
            className={styles.cls54}></path>
          <path
            d='M367.84 347.36V338c0-.95 2.74-.95 2.74 0v9.36z'
            className={styles.cls55}></path>
          <path
            d='M104.8 29.51H110.13V363.51H104.8z'
            className={styles.cls56}></path>
          <path
            d='M194.3 12.51H197.47V376.51H194.3z'
            className={styles.cls56}></path>
          <path
            d='M39.47 139.33H175.22V144.66000000000003H39.47z'
            className={styles.cls56}></path>
          <path
            d='M39.47 250.83H175.22V256.16H39.47z'
            className={styles.cls56}></path>
          <path
            d='M280.3 29.51H285.63V363.51H280.3z'
            className={styles.cls56}></path>
          <path
            d='M214.97 139.33H350.72V144.66000000000003H214.97z'
            className={styles.cls56}></path>
          <path
            d='M214.97 250.83H350.72V256.16H214.97z'
            className={styles.cls56}></path>
          <path
            d='M39.97 31.01H175.97V361.01H39.97z'
            className={styles.cls57}></path>
          <path
            d='M215.97 31.01H351.97V361.01H215.97z'
            className={styles.cls57}></path>
        </g>
      </g>
    </svg>
  );
}
