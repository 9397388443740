import React from "react";
import { Card, CardActionArea, CardContent, Grid, Typography, List, ListItem, ListItemText, Divider } from "@material-ui/core";
import styles from "../WinConfigurator.module.css";
import { ID4000, ID5000, ID7000, ID8000, ID8000TS, EN8000ED, ENView, EN8000TS } from "../images/profiles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    maxWidth: 345,
    minWidth: 245,
    margin: 10,
  },
  media: {
    maxHeight: 190,
    objectFit: "contain",
  },
});

function Profiles({ setProfile, material }) {
  const classes = useStyles();

  let items = [{}];
  if (material === "Kunstoff") {
    items = [
      {
        Profil: "IDEAL 4000",
        Image: ID4000,
        Optik: "Kantige Optik",
        Bautiefe: "70mm Bautiefe",
        Dichtung: "2 Dichtungen",
      },
      {
        Profil: "IDEAL 5000",
        Image: ID5000,
        Optik: "Abgerundete Optik",
        Bautiefe: "70mm Bautiefe",
        Dichtung: "3 Dichtungen",
      },
      /*{
        Profil: "IDEAL Design",
        Image: placeholder,
        Optik: "Flächenbündiges Design",
        Bautiefe: "70mm Bautiefe",
        Dichtung: "3 Dichtungen",
      },*/
      {
        Profil: "IDEAL 7000",
        Image: ID7000,
        Optik: "Flächenversetztes Design",
        Bautiefe: "85mm Bautiefe",
        Dichtung: "2 Dichtungen",
      },
      {
        Profil: "IDEAL 8000",
        Image: ID8000,
        Optik: "Eckige Optik",
        Bautiefe: "85mm Bautiefe",
        Dichtung: "3 Dichtungen",
      },
      {
        Profil: "Energeto 8000 ED",
        Image: EN8000ED,
        Optik: "Kantige Optik",
        Bautiefe: "85mm Bautiefe",
        Dichtung: "Passivehaustauglich",
      },
    ];
  } else if (material === "Kunstoff-Aluminium") {
    items = [
      {
        Profil: "IDEAL TwinSet 8000",
        Image: ID8000TS,
        Optik: "6 Kammer System",
        Bautiefe: "90mm Bautiefe",
        Dichtung: "3 Dichtungen",
      },
      {
        Profil: "Energeto View",
        Image: ENView,
        Optik: "6 Kammer System",
        Bautiefe: "75mm Bautiefe",
        Dichtung: "3 Dichtungen",
      },
      {
        Profil: "Energeto TwinSet 8000ED",
        Image: EN8000TS,
        Optik: "6 Kammer System mit Mitteldichtung",
        Bautiefe: "90mm Bautiefe",
        Dichtung: "Klebetechnologie",
      },
    ];
  }

  return (
    <Grid className={styles.items}>
      <Grid className={styles.containerItems}>
        <Grid container spacing={3} justify='center'>
          {items.map((item) => (
            <Card className={classes.root} key={item.Profil}>
              <CardActionArea onClick={() => setProfile(item.Profil)}>
                <CardContent>
                  <img className={classes.media} src={item.Image} alt={item.Profil} />
                  <Typography gutterBottom variant='subtitle1' component='h2'>
                    {item.Profil}
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText primary={item.Optik} />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText primary={item.Bautiefe} />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText primary={item.Dichtung} />
                    </ListItem>
                  </List>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Profiles;
